export default class FormatService {

   
    FormatAmount(number) { 
        // Round the number to two decimal places
        number = Math.round(number * 100) / 100;
    
        // Convert the number to a string and split it into integer and decimal parts
        let [integerPart, decimalPart] = number.toString().split('.');
    
        // Add commas to the integer part
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
        // Concatenate the formatted integer part with the decimal part
        const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    
        return formattedNumber;
    }
    FormatTitle(data,count=30) 
    {   //split on word 
        // let words = data.split(' ');
        // return words.slice(0, count).join(' ');
        var word='';
        if(data.length<=count)
        {
             word = data.substring(0, count);
        }
        else
        {
            word = data.substring(0, count)+'....';
        }
        return word; 
    }
    FormatToUpperCase(data='') 
    {
        return data.toUpperCase();
    }
    FormatToLowerCase(data='') 
    {
        return data.toLowerCase();
    }
}